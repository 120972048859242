<template>
<div class="row">
  <div v-permission="[report.perm]" :key="report.title" class="col-lg-6 col-xl-4 mb-5" v-for="report in reports">
    <router-link :to="{ name: report.route }">
      <div class="card card-custom mb-8 mb-lg-0 rounded h-100">
        <div class="card-body py-3 px-3 d-flex">
          <div class="d-flex align-items-center p-2">
            <div class="mr-6">
              <span :class="'svg-icon svg-icon-3x svg-icon-info'">
                <inline-svg :src="report.icon" />
              </span>
            </div>
            <div class="d-flex flex-column ">
              <a href="#" class="text-dark text-justify text-hover-primary font-weight-normal font-size-h4 mb-3">
                {{ report.title }}
              </a>
              <div class="text-dark-75 text-justify font-italic ">
                {{ report.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'CompanyReportsIndex',
  components: {},
  mixins: [],
  data() {
    return {
      reports: [
        {
          title: this.$t('REPORTS.SERVICE_SUMS_BY_ORDERS.TITLE'),
          description: this.$t('REPORTS.SERVICE_SUMS_BY_ORDERS.DESCRIPTION'),
          route: 'serviceCategorySumsByOrders',
          perm: 'view_service_category_sums_by_orders',
          icon: '/media/svg/icons/Shopping/Chart-pie.svg',
          color: 'success',
        },
        {
          title: this.$t('REPORTS.NUM_SERVICES_BY_CATEGORY.TITLE'),
          description: this.$t('REPORTS.NUM_SERVICES_BY_CATEGORY.DESCRIPTION'),
          route: 'numServicesByCategory',
          perm: 'view_num_services_by_category',
          icon: '/media/svg/icons/Media/Equalizer.svg',
          color: 'info',
        },
        {
          title: this.$t('REPORTS.NUM_SERVICES_BY_BRANCH.TITLE'),
          description: this.$t('REPORTS.NUM_SERVICES_BY_BRANCH.DESCRIPTION'),
          route: 'numServicesByBranch',
          perm: 'view_num_services_by_branch',
          icon: '/media/svg/icons/Shopping/Chart-line1.svg',
          color: 'warning',
        },
        {
          title: this.$t('REPORTS.SUMS_BY_ORDERS.TITLE'),
          description: this.$t('REPORTS.SUMS_BY_ORDERS.DESCRIPTION'),
          route: 'sumsByOrders',
          perm: 'view_sums_by_orders',
          icon: '/media/svg/icons/Shopping/Cart1.svg',
          color: 'danger',
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.REPORTS_GROUP') }])
  },
}
</script>

<style scoped>

</style>
